import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <svg width="30%" height="30%" viewBox="0 0 679 1105" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_1624_8945)">
            <path d="M0 367.188H678.711V102.051C678.711 40.5273 639.16 0 580.078 0H98.1445C39.5508 0 0 40.5273 0 102.051V367.188ZM135.254 275.391C121.094 275.391 110.352 265.137 110.352 250.488V139.16C110.352 125 120.605 114.746 135.254 114.746C149.414 114.746 159.668 124.512 159.668 139.16V250.488C159.668 264.648 149.902 275.391 135.254 275.391ZM0 909.18H678.711V424.316H0V909.18ZM135.254 657.715C121.094 657.715 110.352 647.949 110.352 633.301V521.973C110.352 507.812 120.605 497.559 135.254 497.559C149.414 497.559 159.668 507.324 159.668 521.973V633.301C159.668 647.461 149.902 657.715 135.254 657.715ZM0 966.309V1002.93C0 1064.45 39.5508 1104.98 98.1445 1104.98H580.078C639.16 1104.98 678.711 1064.45 678.711 1002.93V966.309H0Z" fill="#224094" />
          </g>
          <defs>
            <clipPath id="clip0_1624_8945">
              <rect width="678.711" height="1104.98" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <h1>
          What's in your fridge?
        </h1>
        <p>Under development</p>
      </header>
    </div>
  );
}

export default App;
